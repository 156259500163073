import React from "react";
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import AboutUsPage from "../Pages/AboutusPage/AboutUsPage";
import ContactUs from "../Pages/ContactUs";
import ForParents from "../Pages/ForParents";
import HomePage from '../Pages/HomePage/HomePage';
import NewsPage from "../Pages/NewsPage";
import Gallery from "../Pages/Gallery";
import JoinUs from "../Pages/JoinUs";
import Ebooks from "../Pages/Ebooks";
import Projects from "../Pages/Projects";






const Routing = () => {
    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/aboutUs' element={<AboutUsPage />} />
            <Route path='/parents' element={<ForParents />} exact />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/news' element={<NewsPage />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/join-us' element={<JoinUs />} />
            <Route path='/e-books' element={<Ebooks />} />
            <Route path='/projects' element={<Projects />} />

        </Routes>
    )

}

export default Routing;