import React, { useState } from 'react'
import styled from 'styled-components';

const ListItem = ({ data, callback }: any) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);


    const handleClick = () => {
        if (data.id == 11) {
            callback()
        } else {
            setIsOpen(!isOpen)
        }
    }


    return (
        <ListWrap onClick={handleClick} bgColor={data.bgColor} collapsed={isOpen}>

            <ListItemHeader collapsed={isOpen}>

                <ListTitle collapsed={isOpen}>
                    {data.title}
                </ListTitle>
                <DropDown collapsed={isOpen} src='/assets/images/dropdown.png' alt='arrow icon' />

            </ListItemHeader>

            {
                data.imageUrl &&
                <img src={data.imageUrl} style={{ width: 620, height: 315, margin: '20px auto', display: 'block' }} />
            }
            {
                data.body &&
                <ListItemBody>
                    {data.body}
                </ListItemBody>
            }

            {
                data.goals &&
                <ul>
                    {
                        data.goals.map((text: string, index: number) => (
                            <li key={index}>{text}</li>
                        ))
                    }
                </ul>
            }
            {
                data.objectives &&
                <ul>
                    {
                        data.objectives.map((text: string, index: number) => (
                            <li key={index}>{text}</li>
                        ))
                    }
                </ul>
            }
        </ListWrap>
    )
}

export default ListItem;

const ListWrap = styled.div<{ collapsed?: boolean, bgColor?: string }>`
        max-width: 1000px;
        background-color: ${({ bgColor, collapsed }) => collapsed ? `${bgColor}80` : bgColor};
        height: ${({ collapsed }) => collapsed ? 'fit-content' : '67px'};
        overflow: hidden;
        border-radius: 5px;
        box-sizing: border-box;
        outline: none;
        cursor: pointer;
        margin: 15px;
        transition: all 0.3s ease-in;
    
`;

const ListItemHeader = styled.div<{ collapsed?: boolean, bgColor?: string }>`
    padding: 20px;
    border-bottom: ${({ collapsed }) => collapsed ? '1px solid #FFFFFF' : ''};
    position: relative;
`;
const DropDown = styled.img<{ collapsed?: boolean }>`
    width: 23px;
    position: absolute;
    right: 20px;
    top: 30px;
    transform: ${({ collapsed }) => collapsed ? `rotate(180deg)`: `rotate(0deg)` };
    transition: all 0.3s ease-in;
   

`

const ListTitle = styled.div<{ collapsed?: boolean }>`
    text-align: center;
    font-size: 17px;
    font-feature-settings: "case" on;
    color: ${({ collapsed }) => collapsed ? "#000" : '#FFFFFF'};
    @media (min-width: 1024px) {
        font-size: 20px;
  }
`;

const ListItemBody = styled.div<{ collapsed?: boolean, bgColor?: string }>`
padding: 20px;


`
