import React from "react";
import styled from "styled-components";
import AppLayout from "../Compoents/AppLayout/AppLayout";
import { Ebook } from "../StaticData/Ebooks";

const Ebooks = () => {
    return (
        <AppLayout>
            <Section>
                {Ebook.map((b) => (
                    <a href={b.url} target="_blank">
                        <StyledImageDiv>
                            <Image src={b.cover} />
                        </StyledImageDiv>
                        <Title>{b.title}</Title>
                    </a>
                ))}
            </Section>
        </AppLayout>
    );
};

export default Ebooks;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 100px;
  @media(min-width: 1000px) {
    justify-content: center;
    flex-direction: row;
  align-items: unset;
  }
`;

const StyledImageDiv = styled.div`
  width: 300px;
  height: 423px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Title = styled.div`
  margin-top: 20px;
  max-width: 30ch;
  text-align: center;
  font-feature-settings: "case" on;
`;
