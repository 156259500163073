export const Ebook = [
    {
        id: 1,
        title: 'თავისუფალი მე (7-10 წელი)',
        url: '/assets/pdf/book1.pdf',
        cover: '/assets/pdf/free_me_7-10.png'
    },
    {
        id: 2,
        title:  'თავისუფალი მე (11-14 წელი)',
        url: '/assets/pdf/book1.pdf',
        cover: '/assets/pdf/free_me_11-14.png'
    },
    {
        id: 3,
        title: 'სასკოლო სკაუტური კლუბის ლიდერის სახელმძღვანელო',
        url: '/assets/pdf/book1.pdf',
        cover: '/assets/pdf/scout_book.png'
    },
]
