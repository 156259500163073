export const CompanyInfo = [
    {
        title: 'Სტუმრებისა და მშობლებისათვის',
        id:1,
        routeName: 'parents',
        imageUrl: '/assets/images/guest-and-parents.JPG'
    },
    {
        title: 'Როგორ ჩაერთოთ ჩვენს საქმიანობაში ',
        id: 2,
        routeName: '/join-us',
        imageUrl: '/assets/images/how-to-join.jpg'
    },
    {   
        title: 'Ჩვენი სტრუქტურა და მმართველობა',
        imageUrl: '/assets/images/our-structure.jpg',
        id:3,
        routeName: 'aboutUs'
    },
    {
        title: 'ახალი ამბები',
        id:5,
        routeName: 'news',
        imageUrl: '/assets/images/news.jpg',
    },
    {
        title: 'ჩვენი საერთაშორისო ოჯახი',
        id:6,
        routeName: '/',
        imageUrl: '/assets/images/inter-family.jpg'
    },
    {   
        title: 'ჩვენი პროექტები',
        imageUrl: '/assets/images/projects.jpg',
        id:8,
        routeName: 'projects'
    },
    {
        title: 'ლიდერების სივრცე',
        id:9,
        routeName: '/',
        imageUrl: '/assets/images/leader-space.jpg',
    },
    {   
        title: 'დონაცია და სპონსორობა',
        imageUrl: '/assets/images/donation.jpg',
        id:10,
        routeName: '/'
    },
    {
        title: 'ჩვენი პუბლიკაციები',
        id:11,
        routeName: 'e-books',
        imageUrl: '/assets/images/publications.jpg',
    },



]
