const  images = [
    {   
        id: 1,
        imgUrl: '/assets/images/carousel/image_1.jpg'
    },
    {
        id:3,
        imgUrl: '/assets/images/carousel/image_3.jpg'
    },
    {   
        id: 4,
        imgUrl: '/assets/images/carousel/image_4.jpg'
    },
    {
        id:5,
        imgUrl: '/assets/images/carousel/image_5.jpg'
    },
    {
        id:8,
        imgUrl: '/assets/images/carousel/image_8.jpg'
    },
   
]

 export default images;