import React from 'react'
import styled from 'styled-components';
import AppFooter from '../Footer/AppFooter'
import AppHeader from '../Header/AppHeader'


const AppLayout: React.FC = ({ children }) => {
    return (
        <RootDiv>
            <AppHeader />
            <LayoutBodyWrap>
                {children}
            </LayoutBodyWrap>
            <AppFooter />
        </RootDiv>
    )
};


const RootDiv = styled.div`
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const LayoutBodyWrap = styled.div`
    width: 100%;
    margin: 100px 0 50px 0;
 
   

`

export default AppLayout