import React from "react";
import styled from "styled-components";

const AppFooter = () => {
  return (
    <Footer>
      <Ul>
        <Li>
          <span>ელ ფოსტა: </span>
          <span>&nbsp;georgiangirlscouts@gmail.com</span>
        </Li>
        <Li>
          <span>ტელეფონი:</span>
          <span>&nbsp;+995 551 175 859, </span>
          <span> &nbsp;+995 595 373 047</span>
        </Li>
        <Li>
          <span>მისამართი:</span>
          <span>&nbsp; თბილისი ,</span>
          <span>&nbsp;ჯავახიშვილის 82.</span>
        </Li>
      </Ul>
      <Div>
      <LogoWrap>
          <EuSponsorship src="/assets/images/logo-ka.png" alt="Sponsor logo" />
          <UnfraLogo src="/assets/images/unfra-logo.jpg" alt="Sponsor logo" />
          <UnWomenLogo
            src="/assets/images/unwomen-logo.jpg"
            alt="Sponsor logo"
          />
        </LogoWrap>
        <EuDisclaimer>
          ვებგვერდი შექმნილია ევროკავშირის მხარდაჭერით. მის შინაარსზე სრულად
          პასუხისმგებელია საქართველოს გოგონა სკაუტების ასოციაცია “დია” და
          შესაძლოა, რომ იგი არ გამოხატავდეს ევროკავშირის შეხედულებებს.
        </EuDisclaimer>
       
      </Div>
    </Footer>
  );
};

export default AppFooter;

const Footer = styled.div`
  /* position: fixed;
  bottom: 0; */
  width: 100%;
  /* z-index: 300; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #961953;
  padding: 16px 0;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 0 0 10px 0;
  margin: 0;
  gap: 15px;
  text-decoration: none;
  list-style-type: none;
  align-items: center;
  border-bottom: 1px solid #f6f398;
  @media (min-width: 800px) {
  }
`;

const Li = styled.li`
  padding: 5px 0;
  text-decoration: none;
  font-feature-settings: "case" on;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  @media (min-width: 800px) {
    font-size: 14px;
    flex-direction: row;
  }
`;

const Div = styled.div`
  max-width: 900px;
  padding: 10px;
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  @media (min-width: 800px) {
    justify-content: space-between;
    flex-direction: row;
    align-items: unset;
    gap: 30px;
  }
`;

const EuDisclaimer = styled.span`
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 0 20px;
  @media (min-width: 800px) {
    font-size: 13px;
    text-align: unset;
    padding: unset;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EuSponsorship = styled.img`
  width: 175px;
  height: 36.97px;
  background-color: #FFFFFF;
  @media (min-width: 800px) {
    width: 250px;
    height: 52.82px;
  }
`;
const UnWomenLogo = styled.img`
  width: 56px;
  height: 20px;
  @media (min-width: 800px) {
    width: 112px;
    height: 40px;
  }
`;
const UnfraLogo = styled.img`
  width: 44px;
  height: 20px;
  @media (min-width: 800px) {
    width: 87.95px;
    height: 40px;
  }
`;
