import React, { useEffect } from 'react'
import AppLayout from '../../Compoents/AppLayout/AppLayout'
import ImageCarousel from '../../Compoents/ImageCarousel/ImageCarousel'
import InfoBox from '../../Compoents/InfoBox/InfoBox'
import SocialMediaBox from '../../Compoents/SocialMediaBox/SocialMediaBox'
import { CompanyInfo } from '../../StaticData/PageSections'
import SocialMedia from '../../StaticData/SocialMedia'
import styled from 'styled-components';
import AppFooter from '../../Compoents/Footer/AppFooter'




const HomePage = () => {


  return (
    <AppLayout>

      <ImageCarousel />
      <WrapView>
        {CompanyInfo.map((item, index) => (
          <InfoBox key={index} index={index} item={item} />
        ))}
      </WrapView>
      <SocialMediaView >
        {SocialMedia.map((media, index) => (
          <SocialMediaBox key={index} item={media} />
        ))}
      </SocialMediaView>
    

    </AppLayout>


  )
}

export default HomePage;


const WrapView = styled.div`
  display: flex;
  flex-wrap: wrap;  
  gap: 30px;
  max-width: 1000px;
  margin: 30px auto;
  justify-content: center;
  @media (min-width: 1300px) {
    max-width: 1200px;
  }
  @media (min-width: 1500px) {
    max-width: 1400px;
  }

`

const SocialMediaView = styled.div`
  display: grid;
  max-width: 1400px;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 50%);

  grid-gap: 20px;
  padding: 0 20px;
  @media(min-width: 840px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 50%);
  }
  
`