import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import BurgerMenu from '../AppLayout/BurgerMenu';

const AppHeader = () => {
    return (
        <Header>
            <LogoTitle>
              
                <img src='/assets/images/logo.png' alt='Dia Logo' style={{ width: 100 }} />
            </LogoTitle>
            <Ul>
                <Li>
                    <StyledLink to='/'> Მთავარი</StyledLink>
                </Li>
                <Li>
                    <StyledLink to='/aboutUs' >  ჩვენს შესახებ</StyledLink>
                </Li>
                <Li>
                    <StyledLink to='/gallery'>ფოტო ვიდეო გალერეა</StyledLink>
                </Li>
                <Li>
                    <StyledLink to='/news'>სიახლეები</StyledLink>
                </Li>
                {/* <Li>
                    <StyledLink to='/contact'>კონტაქტი</StyledLink>
                </Li> */}
            </Ul>
            <BurgerMenu />

        </Header>
    )
};

export default AppHeader;


const Header = styled.div`
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #961953;
    display: flex;
    align-items: center;
    padding: 0 15px;
    z-index: 13;
    font-size: 18px;
    @media (min-width: 800px) {
        padding: 0 35px;
    }
`;

const LogoTitle = styled.div`

`

const LogoSubTitle = styled.span`
    font-size: 14px;
`

const Ul = styled.ul`
    margin: 0 0 0 20px;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    display: none;
    align-items: center;
    @media (min-width: 800px) {
       display: flex;
    }
`;

const Li = styled.li`
    margin: 0 15px;
    padding: 5px 0;
    text-decoration: none;
    font-feature-settings: "case" on;
    color: #FFFFFF;
    &:hover {
        border-bottom: 2px solid #f6f398;
        cursor: pointer;
        color: #f6f398
    }
`;

const StyledLink = styled(Link)`

    text-decoration: none;
    color: #FFFFFF;
    &:hover {
        color: #f6f398
    }
`
