import React from 'react'
import AppLayout from '../Compoents/AppLayout/AppLayout';

const ContactUs = () => {
    return (
        <AppLayout>
            <div>
                contact us 
            </div>
        </AppLayout>
    );
};

export default ContactUs