import React from 'react'
import AppLayout from '../Compoents/AppLayout/AppLayout'

const NewsPage = () => {
  return (
    <AppLayout>
        ახალი სიახლე არ მოიძებნა 
    </AppLayout>
  )
}

export default NewsPage