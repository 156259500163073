import React from "react";
import AppLayout from "../Compoents/AppLayout/AppLayout";
import styled from "styled-components";

const ForParents = () => {
  return (
    <AppLayout>
      <PageContainer>
        {/* <PageCover src = '/assets/images/guest-and-parents.JPG'/> */}
        <div>
          <TitleSection bgColor="#674ea7">
            ინფორმაცია მშობლებისათვის
          </TitleSection>
          <div>
            <span>
              რატომ იქნებოდა კარგი თქვენი ქალიშვილისთვის საქართველოს გოგონა
              სკაუტების ორგანიზაცია “დია”- ს წევრობა
            </span>
            <br />
            <br />
            “დია”-ს პროგრამების ერთ-ერთი მნიშვნელოვანი მახასიათებელია ის, რომ
            გოგონებს საშუალება ეძლევათ სხვადასხვა ფორმით, სხვადასხვა გარემოში
            გაეცნონ საკუთარი პოტენციალსა და ინტერესებს, აღმოაჩინონ და
            განივითარონ ის უნარები, ის კომპეტენციები, რომლებიც ყველაზე მეტად
            შესძლებდა მათი უნუკალური შესაძლებლობების რეალიზებისთვის ხელსაყრელი
            გარემოს შექმნას.
            <br />
            <br />
            Როცა თქვენი გოგონა გახდება{""}
            <span style={{ color: "#cc4125" }}> ბაია </span>
            {""}
            <span style={{ color: "#cc4125" }}> ჭიამაია </span> {""}
            <span style={{ color: "#cc4125" }}> ციცინათელა </span> {""} ან {""}
            <span style={{ color: "#cc4125" }}> დია </span>, ის ავტომატურად
            აღმოჩნდება იგივე ასაკის გოგონების ჯგუფში, რომელსაც უხელმძღვანელებს
            სპეციალურად მომზადებული და ორგანიზაციის ფასეულობებზე მდგომი ლიდერი.
            Რაზმის ლიდერები ჩვენს ორგანიზაციაში არიან 18 წლის ზემოთ ახალგაზრდა
            ქალები და ქალბატონები. Ზედა ასაკი ჩვენი მოხალისეებისათვის დაწესებული
            არ გვაქვს, რამეთუ ვთვლით, რომ ნებისმიერი ასაკის ადამიანს, რომელსაც
            სურვილი აქვს ჩაერთოს ჩვენს მოძრაობაში და საკუთარი წვლილი შეიტანოს,
            უნდა ჰქონდეს ამისი საშუალება. Რაზმის ლიდერი კვირაში ერთხელ ხვდება
            ერთ ასაკობრივ ჯგუფში გაერთიანებულ გოგონებს. ასაკიდან გამომდინარე
            რაზმის შეხვედრა გრძელდება 1 საათიდან 2 საათამდე.
            <br />
            <br />
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScRTWntyBus_y57mDxJChPP_FAhRXEpXHuH0avuPB-mLOI8vA/viewform"
              target="_blank"
            >
              ამ ბმულით{" "}
            </a>{" "}
            შეგიძლიათ დაარეგისტრიროთ თქვენი ქალიშვილი საქართველოს გოგონა
            სკაუტების ასოციაცია “დია”-ში
          </div>
        </div>
        <div>
          <TitleSection bgColor="#ff9900">მშობელთა კომენტარები</TitleSection>
          <CommentBoxWrap>
            <CommentBox>
              ,,მას შემდეგ , რაც ჩემი გოგონა თქვენთან გაწევრიანდა, მის მერე უფრო
              მეტი მეგობარი ჰყავს, უფრო სასიამოვნოდ ატარებს დროს და ძალიან
              ბედნიერია , რომ დიდი სკაუტური ოჯახის წევრია” - 11 წლის მარიამის
              დედა
            </CommentBox>
            <CommentBox>
              ,,ძალიან კარგია, როდესაც თქვენნაირი კარგი ადამიანები ასეთ კარგ
              საქმეებს აკეთებთ. ჩემი გოგოები ძალიან ბედნიერები არიან, მუდამ
              სკაუტური ყელსახვევით დადიან ყველგან” - 13 და 14 წლის ნიაკოს და
              ნათიას ბებო
            </CommentBox>
            <CommentBox>
              ,,ჩემი გოგო თქვენთან იზრდება წლებია. ვატყობ, რომ ძალიან უფრო
              აქტიურია, კომუნიკაბელური, რაზმის შეხვედრებიც ძალიან უხარია. ბევრი
              საჭირო და სასარგებლო რამ ისწავლა თქვენთან. დიდი მადლობა თქვენ” -
              12 წლის სალომეს დედა.
            </CommentBox>
          </CommentBoxWrap>
        </div>
        <div>
          <TitleSection bgColor="#3c78d8">
            შეიძლება ისწავლოს, განივითაროს, აღმოაჩინოს გოგონამ რაზმის
            შეხვედრების დროს:
          </TitleSection>
          <ul>
            <li>
              გადალახოს სხვადასხვა შიშები (სიმაღლის, სიბნელის, სიახლის...)
            </li>
            <li>Სცადოს ახალი თამაშები და ფიზიკური აქტივობები</li>
            <li>
              Დამოუკიდებლად დადგას კარავი და მიიღოს მასში ძილის გამოცდილება
            </li>
            <li>
              Გაერკვეს საიდან მოდის საზოგადოებაში არსებული სხეულის სილამაზის
              მითი და როგორ აიმაღლოს თავდაჯერებულობა
            </li>
            <li>
              Მონაწილეობა მიიღოს საკუთარი თემის საჭიროებების გამოკვლევასა და
              მოგვარებაში
            </li>
            <li>იმოგზაუროს და გაეცნოს ახალ ქვეყნებსა და კულტურებს</li>
            <li>განივითაროს უნარები, რომელიც აუცილებლად წაადგება მის CV-ს</li>
            <li>აღმოაჩინოს საკუთარი ფარული ნიჭი</li>
            <li>გადალახოს საჯარო გამოსვლების შიში</li>
            <li>გაეცნოს სხვადასხვა ტექნოლოგიებს</li>
            <li>სცადოს მექანიკოსობა</li>
            <li>აითვისოს პირველი სამედიცინო დახმარება და ა.შ.</li>
          </ul>
        </div>
        <div>
          <TitleSection bgColor="#6aa84f">
            გოგონა სკაუტების პროგრამა უნიკალურ საგანმანათლებლო მეთოდოლოგიაზეა
            დაფუძნებული და 21-ე საუკუნისათვის საჭირო კომპეტენციებს ანვითარებს
          </TitleSection>
          <div>
            Ჩვენ გთავაზობთ{" "}
            <span style={{ textDecoration: "underline" }}>
              {" "}
              მხოლოდ გოგონებისა და ახალგაზრდა ქალებისათვის განკუთვნილ უსაფრთხო
              სივრცეს
            </span>
            , სადაც გოგონები შესძლებენ გამოსცადონ რაცარ ახალი, საინტერესო,
            სასარგებლო, უკეთ გაეცნონ საკუთარ თავს, დაეხმარონ ადამიანებს,
            გაიჩინონ ახალი მეგობრები და ამ პროცესში იხალისონ!
            <br />
            <br />
            Ჩვენ ხელს ვუწყობთ გოგონებს{" "}
            <span style={{ textDecoration: "underline" }}>
              {" "}
              განივითარონ ლიდერული უნარები{" "}
            </span>
            - გოგონები თავად ირჩევენ აქტივობებს, გამოწვევებს და ღონისძიებებს,
            რომლებშიც სურთ მონაწილეობის მიღება, თავად ისახავენ მიზნებს, ჩვენი
            ლიდერები და მოხალისეები კი ეხმარებიან გოგონებს ამ მიზნების
            მიღწევაში. იქიდან გამომდინარე, რომ “დია”-ში გოგონებს საშუალება
            ეძლევათ პრაქტიკულად გაივარჯიშონ ლიდერული უნარების
            განვითარება-გამოყენება, დარწმუნებულები ვართ, რომ ჩვენი პროგრამა ხელს
            უწყობს მომავალი ლიდერების ჩამოყალიბებას.
            <br />
            <br />
            Ჩვენ მუდმივად ვაახლებთ ჩვენს საგანმანათლებლო პროგრამას და
            საერთაშორისო სკაუტური გამოცდილების საუკეთესო პრაქტიკების
            გაზიარება-ადაპტირებას ვაკეთებთ ჩვენი რეალობისათვის. “Დია”-ს ლიდერები
            და პროგრამ-კომიტეტი ცდილობს შექმნას ისეთი პროგრამები, რომელიც
            თანამედროვე გოგონების ინტერესებსა და მოთხოვნებს აკმაყოფილებს.
            <br />
            <br />
            Ჩვენ მუდმივად{" "}
            <span style={{ textDecoration: "underline" }}>
              ვაახლებთ ჩვენს საგანმანათლებლო პროგრამას
            </span>{" "}
            და საერთაშორისო სკაუტური გამოცდილების საუკეთესო პრაქტიკების
            გაზიარება-ადაპტირებას ვაკეთებთ ჩვენი რეალობისათვის. “Დია”-ს ლიდერები
            და პროგრამ-კომიტეტი ცდილობს შექმნას ისეთი პროგრამები, რომელიც
            თანამედროვე გოგონების ინტერესებსა და მოთხოვნებს აკმაყოფილებს.
          </div>
        </div>
      </PageContainer>
    </AppLayout>
  );
};

export default ForParents;

const PageContainer = styled.div`
  padding: 0 20px;
  margin-top: 100px;
  display: grid;
  line-height: 1.5;
  font-size: 16px;
`;

const PageCover = styled.img`
  width: 100%;
  height: auto;
`;

const CommentBoxWrap = styled.div`
  display: grid;
  grid-template-columns: unset;
  grid-template-rows: repeat(3, 1fr);
  gap: 35px;
  @media (min-width: 800px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: unset;
  }
`;
const CommentBox = styled.div`
  text-align: center;
  max-width: 50ch;
  margin: 5px 0;
  @media (min-width: 800px) {
    margin: unset;
  }
`;
const TitleSection = styled.div<{ bgColor: string }>`
  background: ${(props) => props.bgColor};
  padding: 20px;
  color: #ffffff;
  font-size: 14px;
  margin: 30px 0;
  border-radius: 5px;
  font-feature-settings: "case" on;
  @media (min-width: 800px) {
    font-size: 18px;
  }
`;
