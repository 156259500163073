import React from "react";
import AppLayout from "../Compoents/AppLayout/AppLayout";
import styled from "styled-components";

const JoinUs = () => {
  return (
    <AppLayout>
        <StyledDiv>

      
      <a href="https://forms.gle/tQnwiHrRQJdSTdKt8" target="_blank">
        <ListWrap bgColor="#ff00ff">გახდი ჩვენი მოახალისე ლიდერი</ListWrap>
      </a>
      <a href=" https://forms.gle/hyB19yBBCxuAWMh27" target="_blank">
        <ListWrap bgColor="#1155cc">გაწევრიანდი სკაუტურ რაზმში</ListWrap>
      </a>
      </StyledDiv>
    </AppLayout>
  );
};

export default JoinUs;

const StyledDiv = styled.div`
    width: 100%;
    height: auto;
    margin: 100px auto 0 auto;
    max-width: 1000px;
`

const ListWrap = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
  border-radius: 5px;
  padding: 21.5px 0;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  margin: 15px;
  text-align: center;
  font-size: 17px;
  font-feature-settings: "case" on;
  color: #FFFFFF;
  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;
