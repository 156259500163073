import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BurgerMenu = () => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    return (
        <>
            <BurgerMenuItem opened={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
                <Burger opened={menuOpen} />
            </BurgerMenuItem>
            <MenuContent opened={menuOpen}>
                <MenuList>
                    <StyledUl>
                        <StyledLi>
                            <StyledLink to='/'> Მთავარი</StyledLink>
                        </StyledLi>
                        <StyledLi>
                            <StyledLink to='/aboutUs' >  ჩვენს შესახებ</StyledLink>
                        </StyledLi>
                        <StyledLi>
                            <StyledLink to='/gallery'>ფოტო ვიდეო გალერეა</StyledLink>
                        </StyledLi>
                        <StyledLi>
                            <StyledLink to='/news'>სიახლეები</StyledLink>
                        </StyledLi>
                        {/* <StyledLi>
                            <StyledLink to='/contact'>კონტაქტი</StyledLink>
                        </StyledLi> */}
                    </StyledUl>
                </MenuList>
            </MenuContent>
        </>
    );
};

export default BurgerMenu;

const BurgerMenuItem = styled.div<{ opened?: boolean }>`
    position: absolute;
    display: block;
    top: 40px;
    right: 20px;
    z-index: 12;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    @media (min-width: 800px) {
        display: none;
    }
`

const Burger = styled.div<{ opened?: boolean }>`
    width: 30px;
    height: 2px;
    background-color:${({ opened }) => opened ? 'transparent' : '#FFFFFF'};
    transform:${({ opened }) => opened ? ' translateX(-50px)' : ' translateX(0)'};
    border-radius: 5px;
    transition: all 0.5s ease-in-out;

    &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #FFFFFF;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
        transform:${({ opened }) => opened ? 'rotate(45deg) translate(35px, -35px)' : 'translateY(-10px)'} ;
    }

    &::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #FFFFFF;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
        transform:${({ opened }) => opened ? 'rotate(-45deg) translate(35px, 35px)' : 'translateY(10px)'} ;
    }
`;

const MenuContent = styled.div<{ opened?: boolean }>`
    height: ${({ opened }) => opened ? '100%' : 0};
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #961953c7;
    overflow-y: hidden;
    transition: 0.4s;
`
const MenuList = styled.div`
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
`;
const StyledUl = styled.ul`
    color: #FFFFFF;
    text-decoration: none;
    list-style-type: none;
`;

const StyledLi = styled.li`
    margin: 5px 0;
`

const StyledLink = styled(Link)`
font-size: 20px;
   font-feature-settings: "case" on;
        text-decoration: none;
    color: #FFFFFF;

`
    